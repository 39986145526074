@font-face {
  font-family: 'KeepCalm';
  src: local('KeepCalm'), url(./fonts/KeepCalm-Medium.ttf) format('ttf');
}

.App {
  text-align: center;
  background-color: #FFFFFF;
}

.App.home{
  background: rgba(247, 207, 253, 0.1) !important;
}

.App-container .home {
  margin-top: 70px;
}
.App-container .header{
  padding: 30px;
}
.home .header{
  z-index: 998;
  background: #fffafe;
}
.App-header-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #7C42AD;
  position: sticky;
  top: 0;
  z-index: 999;
}

.App-container-top{
  padding-top: 30px ;
}

.App-container > .container-fluid, .App-container > .container{
  padding: 0 !important;
}
