.need-help {
	&-container{
		padding-top: 111px;
	}

	&-content {
		height: 185px;
		left: 136px;
		top: 1239px;

		background: #CD84F1;
		border-radius: 21px;

		.need-help {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			&-image {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 189px;
				height: 225px;

				img {
					margin-top: -20px;
					margin-left: 40px;
				}
			}

			&-info{
				text-align: left;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;

				div{
					margin-left: 80px;
					margin-top: -10px;
				}

				h4{
					font-family: var(--app-font);
					font-style: normal;
					font-weight: 400;
					font-size: 28px;
					line-height: 41px;
					/* identical to box height */
					color: #FFFFFF;
				}

				p{
					font-family: var(--app-second-font);
					font-style: normal;
					font-weight: 400;
					font-size: 20px;
					line-height: 28px;
					color: #FFFFFF;
				}
			}

			&-contact{
				text-align: left;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;

				&-telephone-content{
					padding-top: 50px;

					span{
						font-family: var(--app-second-font);
						font-style: normal;
						font-weight: 400;
						font-size: 17px;
						line-height: 20px;
						color: #FFFFFF;
						margin-left: -22px;
					}
				}

				&-telephone-icon{
					svg{
						background-color: #00f7ff;
						padding: 10px;
						border-radius: 50px;
					}
				}

				&-telephone-text{
					font-family: var(--app-font);
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 30px;

					color: #FFFFFF;

					a{
						text-decoration: none;
						color: #FFFFFF;
					}
				}
			}
		}
	}
}
