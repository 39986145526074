.page {
	padding: 30px;
	text-align: left;

	&-title {
		text-align: left;

		h1 {
			font-family: var(--app-font);
			font-style: normal;
			font-weight: 400;
			font-size: 40px;
			line-height: 54px;
			/* identical to box height */
		}

		color: #000000;

		padding-bottom: 30px;
		padding-top: 50px;
	}

	.about-us-content {
		h2 {
			font-family: var(--app-font);
			font-style: normal;
			font-weight: 400;
			font-size: 50px;
			line-height: 66px;

			color: #000000;
			padding-bottom: 30px;
		}

		p {
			padding-bottom: 20px;

			font-family: var(--app-second-font);
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 35px;

			color: #000000;
		}

		.vision {
			padding-top: 30px;

			h2 {
				font-family: var(--app-font);
				font-style: normal;
				font-weight: 500;
				font-size: 39px;
				line-height: 60px;
				color: #000000;
				padding-bottom: 0 !important;
			}

			&-description {
				font-family: var(--app-second-font);
				font-style: normal;
				font-weight: 400;
				font-size: 20.8px;
				line-height: 27px;
				color: #000000;
			}

			&-list {
				margin-top: 70px;

				.card {
					max-height: 325px;
					margin-bottom: 10%;
				}

				.row > .card {
					margin-bottom: 40px;
				}
			}
		}
	}

	.our-services-content {
		.related-tests-container {
			&-body {
				.card {
					background: #FFFFFF;
					border: 2px solid #005BA4 !important;

					.book-now {
						background: #1048C4 !important;

						span {
							margin-top: 5px;
						}
					}

					&-body {
						padding: 17px 40px 5px 40px;
					}
				}
			}
		}
	}
}
