.footer{

	background: #FFFFFF !important;
	padding-top: 80px;

	&-container{
		.footer-content {
			padding-bottom: 40px;

			&-logo {
				text-align: left;

				p {
					font-family: var(--app-font);
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 22px;

					color: #005BA4;
				}
			}

			&-social{

				svg {
					margin-right: 20px;
					font-size: 26px;
					cursor: pointer;
					color: #000000;
				}
			}

			&-menu{
				.nav-item{
					text-align: left;
					vertical-align: center;

					a {
						display: flex;
						flex-direction: row;
						align-items :center;
						justify-content: flex-start;

						font-family: var(--app-font);
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 29px;
						color: #000000;


						&::before {
							content: '|';
							color: #00C8FC;
							margin-right: 13px;
						}
					}
				}
			}
		}
	}
}
