.info {

	&-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;

		.row{
			width: 100%;
		}

		&-title {
			font-family: var(--app-font);
			font-style: normal;
			font-weight: bold;
			font-size: 17px;
			line-height: 20px;
			color:#00C8FC;
			text-align: left;
		}

		&-icon {
			svg {
				background-color: #00f7ff;
				padding: 10px;
				border-radius: 50px;
				color: #FFFFFF;
			}
		}

		&-body {
			font-family: var(--app-font);
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 30px;
			text-align: left;

			color: #005BA4;

			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			a{
				text-decoration: none;
				color: #005BA4
			}
		}
	}
}
