.home-stats-container{
	.row{

		.col-7 {
			text-align: left;

			&:nth-child(1){
				display:block;

				padding-top: 150px;
				padding-bottom: 150px;
			}

			.status{

				padding-top: 30px;
				padding-bottom: 60px;
				.list-group{
					.list-group-item{
						h5{
							font-family: var(--app-font);
							font-style: normal;
							font-weight: 400;
							font-size: 50px;
							line-height: 144.5%;
							color: #1980FC;
						}

						text-align: center;

						font-family: var(--app-font);
						font-style: normal;
						font-weight: 400;
						font-size:25px;
						line-height: 36px;

						color: #000000;
						background: transparent !important;
						border: none;
						border-right: 2px solid #000000;
						border-radius: 0;
						padding-right: 25px;
						padding-left: 25px;
					}
				}
			}

			h2 {
				font-family: var(--app-font);
				font-style: normal;
				font-weight: 400;
				font-size: 35px;
				line-height: 65px;
				margin-left: 30px;

				color: #000000;

				&:nth-child(2){
					font-size: 40px !important;
				}
			}
		}

		.col-5 {
			display: flex;
			justify-content: center;
			align-items: flex-start  !important;

			img {
				height: 90%;
				width: 100%;
				padding-bottom: 25px;
			}
		}
	}
}
