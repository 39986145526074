.header {
	&-top {
		color: white;
		left: 0;
		top: 0;
		background: var(--app-header-color);
		min-height: 106px;

		&-telephone {
			height: 100px;
			left: 177px;
			top: 30px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			&-icon {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 34px;
				left: 177px;
				top: 39px;

				svg {
					background-color: #00f7ff;
					padding: 10px;
					border-radius: 50px;
				}
			}

			&-text {
				text-align: left;
				width: 260.8px;
				height: 31px;
				left: 238px;
				margin-top: -6px;
				font-family: var(--app-font), Serif;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				color: #FEFFFF;
			}
		}

		&-menu {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			.list-group {
				margin: 0 auto;
				list-style: none;
				display: flex;
				flex-flow: row wrap;

				button {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					float: left;
					width: 126px;
					height: 36px;
					top: 30px;
					background: #FFFFFF;
					border-radius: 37.5px;
					clear: both;
					font-family: var(--app-font);
					font-style: normal;
					font-weight: 400;
					font-size: 13px;
					line-height: 24px;
					color: #005BA4;
					border: none !important;

					margin: 10px;

					&:hover {
						background: #FFFFFF !important;
						color: #005BA4;
					}

					&:nth-child(4){
						width: 170px !important;
					}
				}
			}
		}

		&-social {
			a{
				text-decoration: none;
				color: white !important;
			}

			.list-group{
				justify-content: center;
			}

			svg {
				margin: 0 5px;
				font-size: 15px;
				color: #FFFFFF;
			}

			.info-content{
				margin-bottom: 10px !important;
				margin-left: 30px;
				margin-top: 5px !important;

				.row{
					margin-top: 0 !important;
				}

				&-title{
					font-size: 12px !important;
				}

				&-body{
					line-height: 7px;
					font-size: 12px;


				}

				&-icon{
					svg{
						padding: 7px !important;
					}
				}
			}
		}
	}
}

.location-model{



	.modal{
		&-dialog{
			max-width: 1300px !important;
		}

		&-content {
			background: #7C42AD;
		}


		&-header{

			border-bottom: none !important;
			button {
				background-color: #FFFFFF;
				opacity: 1;
			}
		}

		&-title{

			img {
				width: 50%;
			}
		}

		&-body {
			h2 {
				text-align: center;
				font-family: var(--app-font);
				font-style: normal;
				font-weight: 400;
				font-size: 40px;
				line-height: 66px;
				color: #FFFFFF;
				opacity: 1;
			}
		}
	}

	.location-list{
		margin: 50px 140px;

		button {
			border: 2px solid #FFFFFF;
			border-radius: 37.5px;
			padding: 8px 35px;

			font-family: var(--app-font);
			font-style: normal;
			font-weight: 400;
			font-size: 15px;
			line-height: 24px;
			/* identical to box height */
			background-color: transparent;


			color: #FFFFFF;

			&:hover{
				background-color: transparent;
				color: #FFFFFF;
			}
		}

		&-category{
			margin-bottom: 30px;
		}

		&-sub-category{
			button {
				margin-right: 30px;
				margin-bottom: 30px;
				background: #FFFFFF;

				font-family: var(--app-font);
				font-style: normal;
				font-weight: 400;
				font-size: 15px;
				line-height: 24px;
				color: #005BA4;
			}
		}
	}

}
