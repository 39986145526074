.popular-tests-container-fluid{
	.related-tests-container{
		padding-top: 40px !important;
	}
}
.related-tests-container{
	padding-top: 60px;


	&-title{
		text-align: left;
		margin-bottom: 35px;

		h3{
			font-family: var(--app-font);
			font-style: normal;
			font-weight: 400;
			font-size: 28px;
			line-height: 41px;
			color: #000000;
		}

		.col {
			.btn{
				background: #F7F8FB;
				border: 0.8px solid #005BA4;
				border-radius: 14px;
				margin-right: 30px;
				padding: 14px;
				font-family: var(--app-font);
				font-style: normal;
				font-weight: 400;
				font-size: 15px;
				line-height: 18px;
				color: #000000;
			}
		}
	}

	&-body{
		text-align: left;

		.card{
			background: #FFFFFF;
			border: 2px solid #7C42AD !important;
			border-radius: 18px;
			margin: 5px;

			&-body{
				padding: 17px 40px 15px 40px;
			}

			&-title {
				font-family: var(--app-font);
				font-style: normal;
				font-weight: 400;
				font-size: 15px;
				line-height: 18px;
				color: #000000;
				margin-top: 10px;
			}

			&-text{
				font-family: var(--app-font);
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 18px;

				color: #CBC4C4;
			}

			&-img-top{
				height: 111px !important;
				width: 120px  !important;
			}

			.card-img-basic{
				width: auto !important;
				height: auto !important;
				background: #005BA4;
				border: 2px solid #005BA4;
				border-radius: 35px;
			}

			&-footer{
				padding-left: 15px;
				padding-right: 15px;
				padding-bottom: 28px;
				background-color: transparent !important;
				border-top: none !important;

				.book-now{
					display: flex;
					align-items: center;
					justify-content: center;
					padding-right: 0 !important;
					background: #7C42AD;
					border-radius: 20px 0 0 20px;
					color: #FFFFFF !important;
					margin-right: -3px;

					span, a{
						color: white;
						text-decoration: none;
					}
				}

				.price{
					color: #005BA4;
					text-align: left;
					margin-left: 30px;

					&-value{
						margin-right: 20px;
						font-family: var(--app-font);
						font-style: normal;
						font-weight: 900;
						font-size: 12px;
						line-height: 33px;

						color: #005BA4;
					}

					&-text{
						text-align: right;
						font-family: var(--app-second-font);
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 22px;
					}
				}
			}
		}
	}

	.column{
		margin-bottom: 50px;

		.popular-tests-container-card{
			background: #FCDBF7;
		}

		&:nth-child(1){
			.popular-tests-container-card{
				background: #E0E0F7;

				img{
					right: 0 !important;
				}

				.card-img-overlay{
					img{
						width: 8rem;
						height: 177px;
						margin-top: 7px;
					}
				}
			}

		}

		&:nth-child(2){
			.popular-tests-container-card{
				background: #FCDBF7;

				img{
					right: -4%;
				}

				.card-img-overlay{
					img{
						width: 8rem;
						height: 180.76px;
						margin-top: 0;
					}
				}
			}
		}

		&:nth-child(3){
			.popular-tests-container-card{
				background: #FFF3D3;

				img{
					width: 78%;
					top: 4%;
					height: 15rem;
					right: -6%;
				}

				.card-img-overlay{
					img{
						width: 9.7rem;
						height: 194.09px;
						margin-top: -4px;
						margin-left: -28px;
					}
				}
			}
		}
	}

	.popular-tests-container-card{
		border: none !important;
		height: 12.6rem !important;


		.card-title{
			font-family: var(--app-font);
			font-style: normal;
			font-weight: 900;
			font-size: 19px;
			line-height: 24px;
			color: #000000;
			margin-top: 22px;
		}

		.card-text{
			font-family: var(--app-font);
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 23px;

			color: #363636;
		}

		.card-img-overlay{

			padding: 15px 30px;
			img{
				width: 135px;
				height: 177px;
				margin-top: 41px;
			}

			button{
				margin-top: 24px;
				font-size: 12px;
			}
		}

		&-image{
			text-align: right;
			margin-top: 18px;

			img{
				position: absolute;
				right: -10%;
				top: 6.17%;
				bottom: 66.58%;
				width: 72%;
			}
		}
	}

	&-card {
		&.basic.card.border-secondary {
			border-radius: 42px;

			.card{
				&-text{
					font-size: 8px;
					line-height: revert !important;
				}
			}

			.test-count{
				font-family: var(--app-font);
				font-style: normal;
				font-weight: 400;
				font-size: 15px !important;
				line-height: 18px !important;

				color: #1048C4;
			}
		}
	}
}
