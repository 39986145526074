.our-directors{
	padding-top: 10px;
	padding-bottom: 10px;

	&-title{
		h3 {
			color: #7C42AD;
			font-family: var(--app-font);
			font-style: normal;
			font-weight: 500;
			font-size: 35px;
			line-height: 54px;
		}
	}

	&-list{
		li{
			padding-top: 30px;
			padding-bottom: 30px;

			.figure {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				&-img {
					width: 180px;
					height: 180px;
				}

				&-caption{

					font-family: var(--app-font);
					font-style: normal;
					font-weight: 500;
					font-size: 21px;
					line-height: 38px;

					color: #00AEA0;
				}
			}
		}

		&-image{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		&-content{
			font-family: var(--app-second-font);
			font-style: normal;
			font-weight: 400;
			font-size: 21px;
			line-height: 30px;

			color: #000000;
		}
	}
}
