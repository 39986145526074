.frequently-booked-container{
	&-inner-fluid {
		background: #7C42AD;
	}


	padding: 50px 50px 50px 0;
	text-align: left;

	&-header {
		color: #FFFFFF;
		&-title {
			h3 {
				font-family: var(--app-font);
				font-style: normal;
				font-weight: 400;
				font-size: 38px;
				line-height: 53px;
			}
		}

		&-list{
			padding-top: 20px;
			font-family: var(--app-font);
			color: #FFFFFF;

			.row {
				div {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					padding-bottom: 40px;

					span{
						font-family: var(--app-font);
						font-style: normal;
						font-weight: 400;
						font-size: 22px;
						line-height: 24px;
						/* identical to box height */

						color: #FFFFFF;
					}
				}
			}

			svg{
				margin-right: 20px;
				height: 43px;
				width: 34px;
			}
		}
	}
}
