.header {
	position: sticky;
	top: 106px;
	z-index: 999;
	background: #FFF;

	&-main {
		&-logo {
			text-align: left;

			img {
				width: 100%;
			}
		}

		&-menu {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.nav {
				list-style: none;
				display: flex;
				flex-flow: row wrap;
				margin-bottom: 0 !important;

				.nav-item {
					margin-right: 10px;
					line-height: 24px;
					display: flex;
					align-items: center;
					justify-content: center;

					a{
						color: #000000;
						text-decoration: none;
						font-family: var(--app-font);
						font-style: normal;
						font-weight: 400;
						font-size: 15px;

						&:hover, &:active{
							border-bottom: 2px solid #3584BA;
						}
					}
				}
			}
		}

		&-action {
			text-align: left;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			button, a {
				box-sizing: border-box;
				width: 170px;
				height: 40px;
				left: 1314px;
				top: 177px;
				background: #7C42AD;
				border: none !important;
				border-radius: 26px;
				font-size: 14px;

				&:hover{
					background: rgba(124, 66, 173, 0.88);
				}
			}
		}
	}
}
